<template>
  <div>
    <div class="box_title flex" style="justify-content: space-between;">
      <Title title="编辑稿件内容" :backShow="false"/>
      <div class="flex">
        <button @click.stop="$router.back()" class="but">返回</button>
        <button @click.stop="updateFn" class="but">修改</button>
      </div>
    </div>
    <div class="ct_box">
      <!-- 封面 -->
      <!--      <upLoadImg v-if="$store.state.FillForm.category_id == 4" :imageUrl="$store.state.FillForm.cover_image"-->
      <!--                 title="封面" @getImg="getImg"/>-->

      <div class="coverPageUpload" v-if="$store.state.FillForm.category_id == 4">
        <span>封面</span>
        <el-upload
            class="avatar-uploader"
            action="/api/common/upload"
            :headers="headers"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
        >
          <img v-if="$store.state.FillForm.cover_image!==''" :src="$store.state.FillForm.cover_image"
               class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </div>
      <upLoadVideo v-if="$store.state.FillForm.category_id == 6 || $store.state.FillForm.category_id == 7"
                   title="上传视频" @getVideo="getVideo"/>
      <div class="flex input_box" style="justify-content:flex-start"
           v-show="$store.state.FillForm.category_id == 7">
        <div class="title_box">
          <p class="title">视频预览</p>
        </div>
        <video :src="$store.state.FillForm.video_url" controls="controls" class="contentVideo">
          您的浏览器不支持 video 标签。
        </video>
      </div>

      <div class="flex input_box">
        <div class="title_box">
          <p class="title"><span></span>稿件标题</p>
        </div>
        <input maxlength="50" class="input_text" v-model="$store.state.FillForm.title"
               placeholder="标题建议25字符以内，字符过多的，媒介修改标题不通知"/>
        <span class="num">{{ $store.state.FillForm.title.length }}/50</span>
      </div>
      <!-- <div class="flex input_box">
          <div class="title_box">
              <p class="title">备用短标题</p>
          </div>
          <input class="input_text" v-model="form.shortTitle" placeholder="为了不影响发稿速度，请提供一个短标题（部分媒介标题为20个字符）" />
      </div> -->
      <fillTimer @get_timer="get_timer"/>
      <div class="flex input_box">
        <div class="title_box">
          <p class="title">转载链接</p>
        </div>
        <input class="input_text" v-model="$store.state.FillForm.url"
               placeholder="若填写稿件转载链接，则首选以链接内容发布"/>
      </div>
      <div class="flex input_box">
        <div class="title_box">
          <p class="title">原文链接</p>
        </div>
        <input class="input_text" v-model="$store.state.FillForm.original_url"
               placeholder="例如:https://www.xxx.com/"/>
      </div>
      <div class="flex input_box">
        <div class="title_box">
          <p class="title">备注信息</p>
        </div>
        <input class="input_text" v-model="$store.state.FillForm.remark"
               placeholder="发稿有什么特殊要求请写上，没有请不要填写"/>
      </div>
      <div>
        <fill_upload @upload_success="upload_success"/>
        <div class="flex input_box">
          <div class="title_box2">
            <p class="title"><span></span>稿件内容</p>
          </div>
          <div style="width: 55%;">
            <vue-ueditor-wrap id="tinymce" v-model="$store.state.FillForm.content"
                              :config="myConfig"></vue-ueditor-wrap>
          </div>
        </div>
        <div class="input_box flex">
          <div class="title_box">
            <p class="title"><span></span></p>
          </div>
          <!-- <button class="draft_but" @click="adddRaft">存为草稿</button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from '@/components/UserInfoTitle.vue'
import VueUeditorWrap from 'vue-ueditor-wrap'
import {mapState} from 'vuex'
import {timeCut} from '@/util/timeCut'
import {empty} from '@/util/emptyFillDate'
import {fill_title_rules} from '@/util/fill_title_rules'
import fillTimer from '@/components/fillTimer'
import fill_upload from '@/components/fill_upload'

export default {
  name: '',
  components: {
    Title,
    VueUeditorWrap,
    fillTimer,
    fill_upload
  },
  computed: {
    ...mapState(['FillForm'])
  },
  watch: {
    'FillForm.content': {
      handler(newValue, oldValue) {
        console.log('新值:', newValue);
      },
      // 可以设置 immediate 为 true，让监听在组件创建时就触发一次
      immediate: true
    }
  },
  mounted() {
    console.log(this.$route)
    console.log(this.$store.state.FillForm)
    this.get_list()
    this.headers = {
      'token': this.$user_info.token
    }
  },
  data() {
    return {
      titleList: [{
        id: 1,
        title: '资讯门户发布'
      },
        {
          id: 2,
          title: '自媒体发布'
        },
        {
          id: 3,
          title: '纸媒介发布'
        },
        {
          id: 4,
          title: '公众号发布'
        },
        {
          id: 5,
          title: '微博发布'
        },
        {
          id: 6,
          title: '小红书发布'
        },
        {
          id: 7,
          title: '短视频发布'
        }],
      myConfig: {
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        // 初始容器高度
        initialFrameHeight: 400,
        // 初始容器宽度
        initialFrameWidth: '90%',
        // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
        serverUrl: `/api/common/getupload?token=${this.$user_info.token}`,
        // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
        UEDITOR_HOME_URL: '/UEditor/',
        initialContent: '1.所提供文章必须符合国家广告法内容，若文章存在违规被删除，本平台不予处理；<br/>2.Word文档上传后请仔细检查内容是否有误，一经发布无法撤回和修改，请确认后再操作；<br/>3.署名、作者名、免责声明等特殊内容不保证，网媒没标明不包时效的，默认1个月。<br/>4.图片文件最大上传不超过3MB；<br/>5.视频文件最大上传不超过20MB；。',
        autoClearinitialContent: true,
        toolbars: [
          [
            "fullscreen",
            "source",	// html 源码
            "|",
            "undo",
            "redo",
            "|",
            "link",
            "unlink",
            "|",
            "simpleupload",
            "insertimage",
            "insertvideo",
            "|",
            "bold",
            "forecolor",
            "|",
            "justifyleft",
            "justifycenter",
            "justifyright",
            "justifyjustify",
            "|",
            "fontfamily",
            "fontsize",
            "|",
            "removeformat",
            "formatmatch",
            "autotypeset",
          ]
        ],
      },
      uploadAPI: `/api/common/upload`,
      headers: {},
      formData: {
        type: 'read'
      }

    }
  },
  methods: {
    handleAvatarSuccess(response, file, fileList) {
      // console.log(response.data.url, file, fileList)
      this.$store.state.FillForm.cover_image = response.data.url
    },
    get_timer(time) {
      this.$store.state.FillForm.limit_time = time || []
    },
    getImg(url) {
      console.log(url);
      this.$store.state.FillForm.cover_image = url
    },
    getVideo(url) {
      this.$store.state.FillForm.video_url = url
      console.log(this.$store.state.FillForm.video_url);
      if (this.$route.query.id == 6) {
        this.$store.state.FillForm.content += `<video controls="controls" src="${url}" class="contentVideo"></video>`
        // let d = document.getElementById("contentVideo");
        // console.log(d);
      }
    },
    upload_success(data) {
      console.log(data);
      this.$store.state.FillForm.content = data.content
    },
    get_list() {
      this.curlGet('/api/users/order/detail', {
        id: this.$route.query.id
      }).then(res => {
        if (res.data.code) {
          this.$store.state.FillForm = res.data.data[0]
          console.log('FillForm:', this.$store.state.FillForm);
          if (this.$store.state.FillForm.limit_time) {
            this.$store.state.FillForm.limit_time = this.$store.state.FillForm.limit_time * 1000
          }
          this.tabShow()

        }
      })
    },
    // 修改
    updateFn() {
      if (this.$store.state.FillForm.title == '') {
        return this.$message({
          type: 'warning',
          message: '请输入稿件标题'
        });
      }
      if (this.$store.state.FillForm.content == '') {
        return this.$message({
          type: 'warning',
          message: '请选择文件提取文章或输入文章'
        });
      }
      this.$confirm('是否修改文稿?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '再想想',
      }).then(() => {
        this.$store.state.FillForm.limit_time = this.$store.state.FillForm.limit_time / 1000
        this.curlPost('/api/users/order/save', this.$store.state.FillForm).then(res => {
          if (res.data.code) {
            this.$message({
              type: 'success',
              message: res.data.msg
            });
          }
          console.log(this.$store.state.FillForm);
          if (this.$store.state.FillForm.category_id) {
            this.$router.push('/user/MediaOrder')
            this.$store.state.FillForm = empty()
          } else {
            this.$router.push('/user/PackageOrder')
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消修改'
        });
      });
    },
    // 存为草稿
    adddRaft() {
      const titleStatus = fill_title_rules(this.$store.state.FillForm.title)
      if (!titleStatus) {
        return
      }
      if (this.$store.state.FillForm.title == '' || this.$store.state.FillForm.content == '') {
        return this.$message({
          message: '标题和内容至少要填写一项',
          type: 'warning',
        })
      }
      this.curlPost('/api/users/draft/add', data).then(res => {
        console.log(res);
        if (res.data.code) {
          this.$message({
            message: '添加成功',
            type: 'warning',
          })
          return this.$router.go(-2)
        }
      })
      this.$store.state.FillForm = empty()

    },
    tabShow() {
      switch (this.$route.query.id) {
        case '1':
          this.$store.commit('childTab', '资讯门户发布')
          break;
        case '2':
          this.$store.commit('childTab', '自媒体发布')

          break;
        case '3':
          this.$store.commit('childTab', '纸媒介发布')

          break;
        case '4':
          this.$store.commit('childTab', '公众号发布')

          break;
        case '5':
          this.$store.commit('childTab', '微博发布')

          break;
        case '6':
          this.$store.commit('childTab', '小红书发布')

          break;
        case '7':
          this.$store.commit('childTab', '短视频发布')
          break;
        case '8':
          this.$store.commit('childTab', '套餐发布')

          break;
        default:
          break;
      }
    }
  },
}
</script>

<style lang=scss scoped>
@import '@/scss/Fill';
</style>